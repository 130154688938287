import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import linkedInIcon from "../assets/img/linkedin-icon-white.svg";
import facebookIcon from "../assets/img/facebook-icon-white.svg";
import instagramIcon from "../assets/img/instagram-icon-white.svg";
import { HashLink } from "react-router-hash-link";
import resumePath from "../assets/pdf/Sanjay_Madari_Resume.pdf";

type ActiveLink = "home" | "skills" | "projects";

export const NavBar: React.FC = () => {
  const [activeLink, setActiveLink] = useState<ActiveLink>("home");
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = (): void => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value: ActiveLink): void => {
    setActiveLink(value);
  };

  const handleDownloadResume = async () => {
    try {
     const a = document.createElement('a');
     a.href = resumePath;
     a.download = 'Sanjay_Madari_Resume.pdf';
     document.body.appendChild(a);
     a.click();
     document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading resume:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/" aria-label="Home">
          <h1 className="white">Galaxian</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="#home"
              className={activeLink === "home" ? "active navbar-link" : "navbar-link"}
              onClick={() => onUpdateActiveLink("home")}
              aria-current={activeLink === "home" ? "page" : undefined}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={activeLink === "skills" ? "active navbar-link" : "navbar-link"}
              onClick={() => onUpdateActiveLink("skills")}
              aria-current={activeLink === "skills" ? "page" : undefined}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={activeLink === "projects" ? "active navbar-link" : "navbar-link"}
              onClick={() => onUpdateActiveLink("projects")}
              aria-current={activeLink === "projects" ? "page" : undefined}
            >
              Projects
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/sanjaymadari" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <img src={linkedInIcon} alt="LinkedIn" />
              </a>
              <a href="https://www.facebook.com/Sanjay.Smart.King" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <img src={facebookIcon} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/its_only_mr.sanju" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <img src={instagramIcon} alt="Instagram" />
              </a>
            </div>
            <button className="vvd" onClick={handleDownloadResume} aria-label="Download Resume">
              <span>Download Resume</span>
            </button>
            <HashLink to="#connect">
              <button className="vvd" aria-label="Let's Connect">
                <span>Let's Connect</span>
              </button>
            </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}; 
