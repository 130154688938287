import { Container, Row, Col } from "react-bootstrap";
import linkedInIcon from "../assets/img/linkedin-icon-white.svg";
import facebookIcon from "../assets/img/facebook-icon-white.svg";
import instagramIcon from "../assets/img/instagram-icon-white.svg";

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <h1 className="white">Galaxian</h1>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/sanjaymadari" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <img src={linkedInIcon} alt="LinkedIn" />
              </a>
              <a href="https://www.facebook.com/Sanjay.Smart.King" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <img src={facebookIcon} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/its_only_mr.sanju" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <img src={instagramIcon} alt="Instagram" />
              </a>
            </div>
            <p>Copyright 2025. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}; 
